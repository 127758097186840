@import url(./static/fonts/typography.css);

* {
  box-sizing: border-box;
  font-family: "Helvetica Neue Regular", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Helvetica Neue Light", sans-serif;
  color: #000000;
}

h5 {
  font-size: 1rem;
  margin: 1rem 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.bullet-list {
  list-style: disc;
  padding: 0 0 0 20px;
  margin: 1rem 0;
}

a {
  text-decoration: none;
  color: #000000;
}

a:hover {
  text-decoration: underline;
  color: #000000;
}

input[type="text"],
input[type="number"],
input[type="password"] {
  padding: 0.766rem;
}

button {
  min-width: "unset";
  border-radius: 0.2rem;

  @media screen and (max-width: 360px) {
    min-width: "unset";
  }
}

.lighter-text {
  color: #575757;
}

p a {
  color: #3573b1;
  background-color: transparent;
  text-decoration: none;
  border: none;
  font-size: 1rem;
  padding: 0;
  font-weight: 400;
  margin: 0;
  min-width: unset;
  cursor: pointer;
}

p a:hover {
  color: #3573b1;
  text-decoration: underline;
  background-color: transparent;
}

.react-calendar__tile {
  background-color: transparent;
  border: none;
  border-radius: unset;
  min-width: unset;
  padding: 0.5rem;
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;
}

.react-calendar__month-view__weekdays__weekday abbr[title] {
  text-decoration: none;
}
