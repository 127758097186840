@import url('https://fast.fonts.net/t/1.css?apiType=css&projectid=09529873-f1ee-4caa-a99d-bccada8c6d05');

@font-face {
  font-family: 'Helvetica Neue Light';
  src: url('./1b950d2a-907a-4888-8056-0d44e8e86338.eot?#iefix');
  src: url('./1b950d2a-907a-4888-8056-0d44e8e86338.eot?#iefix')
      format('eot'),
    url('./3a46542f-f429-4821-9a88-62e3be54a640.woff2')
      format('woff2'),
    url('./50ac1699-f3d2-47b6-878f-67a368a17c41.woff') format('woff'),
    url('./0be5590f-8353-4ef3-ada1-43ac380859f8.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue Regular';
  src: url('./bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix');
  src: url('./bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix')
format('eot'),
    url('./3dac71eb-afa7-4c80-97f0-599202772905.woff2')
      format('woff2'),
    url('./34e0e4c0-c294-49bb-9a8e-1b2cafd54e32.woff') format('woff'),
    url('./8a8bfee3-197c-4942-9b11-71508cc9f406.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue Bold';
  src: url('./9697cfef-7816-47cc-81ed-c56c029d3bcf.eot?#iefix');
  src: url('./9697cfef-7816-47cc-81ed-c56c029d3bcf.eot?#iefix')
      format('eot'),
    url('./1f4274cd-2674-481e-9023-69e33ffca161.woff2')
      format('woff2'),
    url('./5e8d493c-be01-44b9-962e-504a5cf10dd8.woff') format('woff'),
    url('./ae36b4a0-aa2c-46cc-98d8-d8b7d22241dc.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue Medium';
  src: url('./5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix');
  src: url('./5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix')
      format('eot'),
    url('./240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2')
      format('woff2'),
    url('./7802e576-2ffa-4f22-a409-534355fbea79.woff') format('woff'),
    url('./14d824a2-2a5c-4692-8e30-74f6e177b675.ttf')
      format('truetype');
}
